<template>
  <Layout>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <div v-if="showDashboard" class="columns is-flex-wrap-wrap">
        <div
          v-for="(tutorial, index) in tutorials"
          :key="index"
          class="column is-half"
        >
          <card-component :title="tutorial.title">
            <p>
              {{ tutorial.description }}
              <a
                :href="tutorial.url"
                target="_blank"
                rel="noopener noreferrer"
              >
                ... Ver Más
                <b-icon icon="open-in-new" custom-size="default"/>
              </a>
            </p>
          </card-component>
        </div>
      </div>
    </section>
  </Layout>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import Layout from '@/components/Layout'
import CardComponent from '@/components/CardComponent'

export default {
  components: {
    TitleBar,
    Layout,
    CardComponent
  },
  data () {
    return {
      showDashboard: true,
      tutorials: [
        {
          title: '¿Cómo generar un rotulado para envíos delivery?',
          description: 'Para ayudarte a enviar tus pedidos de la manera más rápida, hemos habilitado la opción para descargar el rotulado con los datos ',
          url: 'https://medium.com/app-weeare-pe/c%C3%B3mo-generar-un-rotulado-para-env%C3%ADos-delivery-517a53099172'
        },
        {
          title: 'Cambia a modo oscuro',
          description: 'Estamos mejorando constantemente la plataforma para que sea más fácil de usar y tenga más funcionalidades que te permitan ',
          url: 'https://medium.com/app-weeare-pe/cambia-a-modo-oscuro-7df28d113e58'
        },
        {
          title: '¿Cómo gestiono un pedido con Delivery?',
          description: 'En la plataforma de Regalos Weeare los usuarios pueden acceder a muchas opciones de regalos y algunas vienen con delivery. Los ',
          url: 'https://medium.com/app-weeare-pe/c%C3%B3mo-gestiono-un-pedido-con-delivery-426c4e2068b0'
        },
        {
          title: 'Historial de validaciones',
          description: 'Cada vez que se genera un consumo este pasa al historial de consumos. Estas son las funcionalidades del Historial ',
          url: 'https://medium.com/app-weeare-pe/historial-de-consumos-d801d442b77d'
        },
        {
          title: '¿Cómo cobrar la subvención de descuentos o Regalos Weeare?',
          description: 'Al momento de atender a un cliente es importante que pase un buen momento sin estar realizando trámites engorroso para ',
          url: 'https://medium.com/app-weeare-pe/c%C3%B3mo-cobrar-la-subvenci%C3%B3n-de-descuentos-o-regalos-weeare-b44fec732cda'
        },
        {
          title: '¿Cómo validar un Regalo?',
          description: 'Para registrar un Regalo se deberá solicitar el documento de identidad en físico o el PDF en físico o digital con el código de ',
          url: 'https://medium.com/app-weeare-pe/c%C3%B3mo-validar-un-regalo-5121c0a716f5'
        },
        {
          title: '¿Cómo registrar un descuento en el establecimiento?',
          description: 'Para registrar un Nuevo Consumo se deberá solicitar el documento de identidad en físico o el PDF en físico o digital con el código de ',
          url: 'https://medium.com/app-weeare-pe/c%C3%B3mo-validar-un-descuento-en-el-establecimiento-ae8166f3f646'
        }
      ]
    }
  },
  computed: {
    titleStack () {
      return [
        'Soporte',
        'Ayuda'
      ]
    }
  }
}
</script>
